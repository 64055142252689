<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          class="text-white"
          v-bind:class="'bg-' + toast.level"
          :key="'toast' + index"
          :show="true"
        >
          {{ toast.message }}
        </CToast>
      </template>
    </CToaster>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes"/> お知らせ登録
      </CCardHeader>
      <CCardBody>
        <CRow>
        <CCol md="6">
          <CInput
            label="期間（開始）"
            type="date"
            v-model="form.startDate"
            :lazy="true"
            :isValid="checkIfValid('startDate')"
          />
        </CCol>
        <CCol md="6">
          <CInput
            label="期間（終了）"
            type="date"
            v-model="form.endDate"
            :lazy="true"
            :isValid="checkIfValid('endDate')"
          />
        </CCol>
      </CRow>
        <CRow>
          <CCol>
            <CInput
              label="タイトル"
              placeholder=""
              v-model="form.title"
              :lazy="true"
              :isValid="checkIfValid('title')"
              invalidFeedback="入力してください。"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CTextarea
              label="本文"
              placeholder=""
              rows="10"
              v-model="form.body"
              :lazy="true"
              :isValid="checkIfValid('body')"
              invalidFeedback="入力してください。"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol class="md-3">
            <CButton color="info" @click="submit">登録する</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes"/> 登録済みお知らせ一覧
      </CCardHeader>
      <CCardBody>
          <CDataTable :items="infos" :fields="fields" sorter>
            <template #startDate="{ item }">
              <td class="text-center">{{ item.startDate }}</td>
            </template>
            <template #endDate="{ item }">
              <td class="text-center">{{ item.endDate }}</td>
            </template>
            <template #title="{ item }">
              <td class="text-left">{{ item.title }}</td>
            </template>
            <template #body="{ item }">
              <td class="text-left">{{ item.body }}</td>
            </template>
            <template #button="{ item }">
              <td class="text-center">
                <CButton color="info" @click="onDeleteInfo(item.id)">削除する</CButton>
              </td>
            </template>
          </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Vue from 'vue';
import firebase from '@firebase/app';
import { uuid } from 'vue-uuid'
 
const fields = [
  { key: "startDate", label: "開始日", _style: "width:10%; text-align:center;", },
  { key: "endDate", label: "終了日", _style: "width:10%; text-align:center;", },
  { key: "title", label: "タイトル", _style: "width:25%; text-align:center;", },
  { key: "body", label: "本文", _style: "width:45%; text-align:center;", },
  { key: "button", label: "", _style: "width:10%;", },
];

Vue.use(uuid);

export default {
  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required
      },
      body: {
        required
      },
      startDate: {
        required
      },
      endDate: {
      },
    }
  },
  data () {
    return {
      account: {},
      form: {},
      toasts: [],
      infos: [],
      fields,
    }
  },
  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    
    getEmptyForm () {
      return {
        startDate: "",
        endDate: "",
        title: "",
        body: "",
        id:"",
        created:"",
      }
    },

    submit() {
      this.$v.$touch();
      if (!this.isValid) {
        return;
      }
      this.saveInfo();
    },

    async saveInfo() {
      let tmp = Vue.util.extend({}, this.form);
      if (this.form.startDate != null && this.form.startDate != '') {
        tmp.startDate = new Date(this.form.startDate + 'T00:00:00Z')
      }
      if (this.form.endDate != null && this.form.endDate != '') {
        tmp.endDate = new Date(this.form.endDate + 'T00:00:00Z')
      } else {
        tmp.endDate = new Date('9999-12-31T00:00:00Z')
      }
      tmp.id = uuid.v4()
      tmp.created = firebase.firestore.FieldValue.serverTimestamp()
      await firebase.firestore().collection("infos").doc(tmp.id).set(tmp)
      this.fetchInfos()
      this.toasts.push({ level: "success", message: "登録しました。" });
    },

    async onDeleteInfo(id) {
      const ok = confirm('本当に削除しますか？')
      if (!ok) {
        return
      }
      await firebase.firestore().collection("infos").doc(id).delete()
      this.fetchInfos()
    },

    async fetchInfos() {
      const infosSnapshot = await firebase.firestore().collection("infos").orderBy('created').get()
      let infos = []
      for (let infoDoc of infosSnapshot.docs) {
        let info = infoDoc.data()
        info.startDate = this.fromatDate(info.startDate)
        info.endDate = this.fromatDate(info.endDate)
        infos.push(info)
      }
      this.infos = infos
    },

    fromatDate(timestamp) {
      if (timestamp == null) return null;
      let date = timestamp.toDate()
      return `
      ${date.getFullYear()}/${(date.getMonth() + 1)
        .toString()
        }/${date.getDate().toString()}
      (${["日", "月", "火", "水", "木", "金", "土"][date.getDay()]})
      `.replace(/\n|\r/g, "");
    },

    async init () {
      // 初期化
      this.$store.commit("showLoading")
      await this.fetchInfos()
      this.$store.commit("hideLoading")
    },
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
